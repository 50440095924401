import React from "react";
import "./Home.css";
import video from "../GrafinImg/grafinvideo.mp4";
import approach1 from "..//GrafinImg/approach1.png";
import approach2 from "..//GrafinImg/approach2.png";
import approach3 from "..//GrafinImg/approach3.png";
import approach4 from "..//GrafinImg/approach4.png";
import app1 from "..//GrafinImg/app1.png";
import app2 from "..//GrafinImg/app4.png";
import app3 from "..//GrafinImg/app3.png";
import app4 from "..//GrafinImg/app2.png";
import app5 from "..//GrafinImg/app5.png";
import app6 from "..//GrafinImg/app6.png";


const Home = () => {
  return (
    <div>
      {/* this is first section  */}
      <section>
        <div className="container-video">
          <div className="video">
            <video autoPlay loop muted>
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <div className="video-text">
            <h1 className="video-fond1">
              Where Vision
              <br />
              Meet Innovation
            </h1>
          </div>
          <div className="video-para">
            <p className="para">
              Our expertise turns ideas into
              <br />
              transformative technology for
              <br />a smarter future."
            </p>
          </div>
        </div>
      </section>
      {/* this is second section */}
        <section className="svg-section">
        <div className="svg-container">
          <svg
            className="svg-box"
            width="410"
            height="440"
            viewBox="0 0 377 384"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="draw"
              d="M-0.5 3.75836C-0.5 3.75836 186.599 -2.44793 198.5 3.75833C215 12.3628 197.747 55.3973 209.434 66.4747C221.121 77.5521 298.552 66.415 309 74.5C314.815 79 308.815 97.5521 314.5 106.5C320.185 115.448 376.5 112 376.5 112"
              stroke="url(#paint0_linear_1117_484)"
              stroke-width="2"
            />
            <path
              className="draw"
              d="M0 382.323C0 382.323 191.099 381.706 203 375.5C214.901 369.294 197.747 330.684 209.434 319.606C221.121 308.529 311.81 323.923 317.5 312C322.58 301.357 315.605 290.625 324 281C328.361 276 375.5 276 375.5 276"
              stroke="url(#paint1_linear_1117_484)"
              stroke-width="2"
            />
            <path
              className="draw"
              d="M0.5 49.5C0.5 49.5 75.0332 44.9 85 49.5C104.5 58.5 84.5 105.5 99 113.5C113.5 121.5 211.176 109.548 215.5 121.5C219.36 132.17 213.224 142.868 219.5 150C225.776 157.132 344 154.5 344 154.5"
              stroke="url(#paint2_linear_1117_484)"
              stroke-width="2"
            />
            <path
              className="draw"
              d="M0 342.316C0 342.316 86.7873 344.722 95.8309 338.5C109.5 329.096 93.8309 282 105.5 275.5C117.922 268.581 222.14 280.17 226 269.5C229.86 258.83 222.5 239.5 235.5 239.5C253.5 239.5 365.5 239.5 365.5 239.5"
              stroke="url(#paint3_linear_1117_484)"
              stroke-width="2"
            />
            <path
              className="draw"
              d="M2 85.4999C2 85.4999 123 76.2034 133 85.5C143 94.7966 132.5 162.5 143 171C153.5 179.5 227 177.5 227 177.5H272H312.5"
              stroke="url(#paint4_linear_1117_484)"
              stroke-width="2"
            />
            <path
              className="draw"
              d="M0 293.221C0 293.221 50.0788 293.221 55.5 289.5C69.5 279.89 51.5 229 66 216C80.5 203 220.5 210 220.5 210H273H336"
              stroke="url(#paint5_linear_1117_484)"
              stroke-width="2"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1117_484"
                x1="3.03089e-08"
                y1="49.3023"
                x2="569"
                y2="49.3023"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#9F1972" />
                <stop offset="1" stop-color="#5315BE" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_1117_484"
                x1="0"
                y1="336.779"
                x2="569"
                y2="336.779"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#9F1972" />
                <stop offset="1" stop-color="#5315BE" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_1117_484"
                x1="-1.4727e-08"
                y1="89.4236"
                x2="432.38"
                y2="89.4236"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#9F1972" />
                <stop offset="1" stop-color="#5315BE" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_1117_484"
                x1="0"
                y1="296.657"
                x2="432.38"
                y2="296.657"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#9F1972" />
                <stop offset="1" stop-color="#5315BE" />
              </linearGradient>
              <linearGradient
                id="paint4_linear_1117_484"
                x1="2.12481e-07"
                y1="132.279"
                x2="435.273"
                y2="132.279"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#9F1972" />
                <stop offset="1" stop-color="#5315BE" />
              </linearGradient>
              <linearGradient
                id="paint5_linear_1117_484"
                x1="0"
                y1="247.339"
                x2="435.481"
                y2="247.339"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#9F1972" />
                <stop offset="1" stop-color="#5315BE" />
              </linearGradient>
            </defs>
          </svg>
          <div className="svg-box">
            <h2 className="svg-font">
              Creating Pathways For Seamles Collaboration Among Business
            </h2>
          </div>
          <svg
            className="svg-box"
            width="410"
            height="440"
            viewBox="0 0 378 384"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="draw"
              d="M378 3.75836C378 3.75836 190.901 -2.44793 179 3.75833C162.5 12.3628 179.753 55.3973 168.066 66.4747C156.379 77.5521 78.9481 66.415 68.5 74.5C62.6847 79 68.6847 97.5521 63 106.5C57.3153 115.448 1 112 1 112"
              stroke="url(#paint0_linear_1158_452)"
              stroke-width="2"
            />
            <path
              className="draw"
              d="M377.5 382.323C377.5 382.323 186.401 381.706 174.5 375.5C162.599 369.294 179.753 330.684 168.066 319.606C156.379 308.529 65.6905 323.923 60 312C54.9201 301.357 61.8949 290.625 53.5 281C49.1389 276 2 276 2 276"
              stroke="url(#paint1_linear_1158_452)"
              stroke-width="2"
            />
            <path
              className="draw"
              d="M377 49.5C377 49.5 302.467 44.9 292.5 49.5C273 58.5 293 105.5 278.5 113.5C264 121.5 166.324 109.548 162 121.5C158.14 132.17 164.276 142.868 158 150C151.724 157.132 33.5 154.5 33.5 154.5"
              stroke="url(#paint2_linear_1158_452)"
              stroke-width="2"
            />
            <path
              className="draw"
              d="M377.5 342.316C377.5 342.316 290.713 344.722 281.669 338.5C268 329.096 283.669 282 272 275.5C259.578 268.581 155.36 280.17 151.5 269.5C147.64 258.83 155 239.5 142 239.5C124 239.5 12 239.5 12 239.5"
              stroke="url(#paint3_linear_1158_452)"
              stroke-width="2"
            />
            <path
              className="draw"
              d="M375.5 85.4999C375.5 85.4999 254.5 76.2034 244.5 85.5C234.5 94.7966 245 162.5 234.5 171C224 179.5 150.5 177.5 150.5 177.5H105.5H65"
              stroke="url(#paint4_linear_1158_452)"
              stroke-width="2"
            />
            <path
              className="draw"
              d="M377.5 293.221C377.5 293.221 327.421 293.221 322 289.5C308 279.89 326 229 311.5 216C297 203 157 210 157 210H104.5H41.5"
              stroke="url(#paint5_linear_1158_452)"
              stroke-width="2"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1158_452"
                x1="377.5"
                y1="49.3023"
                x2="-191.5"
                y2="49.3023"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#9F1972" />
                <stop offset="1" stop-color="#5315BE" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_1158_452"
                x1="377.5"
                y1="336.779"
                x2="-191.5"
                y2="336.779"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#9F1972" />
                <stop offset="1" stop-color="#5315BE" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_1158_452"
                x1="377.5"
                y1="89.4236"
                x2="-54.8799"
                y2="89.4236"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#9F1972" />
                <stop offset="1" stop-color="#5315BE" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_1158_452"
                x1="377.5"
                y1="296.657"
                x2="-54.8799"
                y2="296.657"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#9F1972" />
                <stop offset="1" stop-color="#5315BE" />
              </linearGradient>
              <linearGradient
                id="paint4_linear_1158_452"
                x1="377.5"
                y1="132.279"
                x2="-57.7725"
                y2="132.279"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#9F1972" />
                <stop offset="1" stop-color="#5315BE" />
              </linearGradient>
              <linearGradient
                id="paint5_linear_1158_452"
                x1="377.5"
                y1="247.339"
                x2="-57.9813"
                y2="247.339"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#9F1972" />
                <stop offset="1" stop-color="#5315BE" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </section>  
      {/* this is thrid section */}
      <section>
        <div class="card-container">
          <div className="heading-container">
            <h1 className="card-heading">Our Service</h1>
          </div>
          <div className="main-row1">
            <div class="card1">
              <img src={app1} alt="Mobile App Development" />
              <h3>Mobile App Development</h3>
            </div>
            <div class="card1">
              <img src={app2} alt="Web Development" />
              <h3>Web Development</h3>
            </div>
            <div class="card1">
              <img src={app3} alt="Web Development" />
              <h3>Software Development</h3>
            </div>
          </div>
          <div className="main-row2">
            <div class="card1">
              <img src={app4} alt="Web Development" />
              <h3>Artificial Intelligence</h3>
            </div>
            <div class="card1">
              <img src={app5} alt="Web Development" />
              <h3>UI/UX Design System</h3>
            </div>
            <div class="card1">
              <img src={app6} alt="Web Development" />
              <h3>IoT Solutions</h3>
            </div>
          </div>
        </div>
      </section>

      {/* this is fourth section */}
      <section>
        <div className="approach-containermain">
          <div className="approach-container">
            <h1 className="approach-heading">Our Approach</h1>
          </div>

          <div className="hover-container">
            <div className="image-wrapper">
              <img src={approach1} alt="Approach 1" className="hover-image" />
              <div className="overlay">
                <h2 className="overlay-header">Consulting</h2>
              </div>
              <div className="hover-content1">
                <p>
                  We provide strategic
                  <br /> consulting to drive innovation,streamlin processes, and
                  <br /> empower businesses to <br />
                  each their full 
                  <br />
                  potential.
                </p>
              </div>
            </div>
            <div className="image-wrapper">
              <img src={approach2} alt="Approach 2" className="hover-image" />
              <div className="overlay">
                <h2 className="overlay-header">Solution</h2>
              </div>
              <div className="hover-content2">
                <p>
                  We provide expert consulting solutions at Grafin, helping
                  businesses navigate complex IT challenges and achieve
                  digital excellence.
                </p>
              </div>
            </div>
            <div className="image-wrapper">
              <img src={approach3} alt="Approach 3" className="hover-image" />
              <div className="overlay">
                <h2 className="overlay-header">Support</h2>
              </div>
              <div className="hover-content3">
                <p>
                  "We provide dedicated support at Grafin ensuring seamless IT
                  operations and empowering your business to thrive."
                </p>
              </div>
            </div>
            <div className="image-wrapper">
              <img src={approach4} alt="Approach 4" className="hover-image" />
              <div className="overlay">
                <h2 className="overlay-header">Efficiency Boost</h2>
              </div>
              <div className="hover-content4">
                <p>
                  "We provide solutions designed to boost efficiency,
                  streamlining processesand maximizing productivity
                  forsustained growth."
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* this is fourth section */}
      <section>
        <div className="portfolio-container">
          <h1 className="container-heading">Our Products</h1>
          <div className="row">
            {/* First Container */}
            <div className="single-image-container">
              <div className="add-content1">
                <h1 className="project-heading1">GRAFIN</h1>
                <h3 className="project-heading2">E-Commerce </h3>
              </div>
            </div>

            {/* Second Container */}
            <div className="grid-container">
              <div className="grid-item1">
                <div className="add-content2">
                  <h1 className="project-heading3">JINFLO</h1>
                  <h3 className="project-heading4">Internet of thinks</h3>
                </div>
              </div>
              <div className="grid-item2">
                <div className="add-content3">
                  <h1 className="project-heading5">LERMAX</h1>
                  <h3 className="project-heading6">LMS</h3>
                </div>
              </div>
              <div className="grid-item3">
                <div className="add-content4">
                  <h1 className="project-heading7">EDUCLAR</h1>
                  <h3 className="project-heading8">Smart School</h3>
                </div>
              </div>
              <div className="grid-item4">
                <div className="add-content5">
                  <h1 className="project-heading9">SUDO</h1>
                  <h3 className="project-heading10">Food Delivery</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  
    </div>
  );
};

export default Home;
