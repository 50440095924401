import React from 'react'
import "./Approach.css";
import frame1 from "..//GrafinImg/frame1.jpg";
import frame2 from "..//GrafinImg/frame2.png";
import frame3 from "..//GrafinImg/process.jpg";
import frame4 from "..//GrafinImg/frame4.png";
import frame5 from "..//GrafinImg/frame5.jpg";

const Approach = () => {
  return (
  
        <section>
          <div className='containers'>
          <div className="process-container">
            <h1 className='process-heading'>Explore Our Process</h1>
            </div>
            <div className="rows">
              <div className="colums-1">
                <div className='process-image'>
                <img
                  className="colum1-image"
                  src={frame1}
                  alt="Discovery & Planning"
                />
                </div>
                <div class="overlay1">
                  <h2 className="overlay-heading1">Discovery & Planning</h2>

                  <h2 className="overlay-heading2">
                    Understand client needs, target <br />
                    audience, and unique requirements.
                  </h2>
                </div>
              </div>
              <div className="colums-2">
                <div className='process-image'>
                <img
                  className="colum2-image"
                  src={frame2}
                  alt="Discovery & Planning"
                />
                </div>
                <div class="overlay2">
                  <h2 className="overlay-heading21">Design & Prototyping</h2>

                  <h2 className="overlay-heading22">
                    Create a visually appealing
                    <br />
                    and intuitive layout.
                  </h2>
                </div>
              </div>
            </div>
            <div className="rows">
              <div className="colums-11">
                <div className="parent-img1">
                  <img
                    className="images1"
                    src={frame3}
                    alt="Discovery & Planning"
                  />

                  <div class="inner-text">
                    <h2 className="inner1">Discovery & Planning</h2>

                    <h2 className="inner2">
                      Understand client needs, target
                      <br />
                      audience, and unique requirements.
                    </h2>
                  </div>
                </div>
                <div className="parent-img2">
                  <img
                    className="images2"
                    src={frame4}
                    alt="Discovery & Planning"
                  />
                  <div class="second-inner">
                    <h2 className="inner3">Testing & Optimization</h2>

                    <h2 className="inner4">
                    Ensure the website is functional,<br/>
                    fast, and bug-free.
                    </h2>
                  </div>
                </div>
              </div>
              <div className="colums-12">
                <div className='process-image'>
                <img
                  className="images-1"
                  src={frame5}
                  alt="Discovery & Planning"
                />
                </div>
                <div class="inner-content">
                  <h2 className="inner-div1">Launch & Post-Launch Help</h2>

                  <h2 className="inner-div2">
                    Successfully deploy the site
                    <br />
                    and provide ongoing support..
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>
    
  )
}

export default Approach;
