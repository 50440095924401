import React from "react";
import "./Footer.css";
import insta from "../GrafinImg/instagram.png";
import facebook from "../GrafinImg/facebook.png";
import whatsapp from "../GrafinImg/whatsapp.png";
import linkdin from "../GrafinImg/linkdin.png";

const Footer = () => {
  return (
      <footer className="footer-container">
        <div className="footer-content">
          <div className="footer-logo">GRAFIN</div>
          <div className="footer-links">
            <div className="footer-para">
              <p>@2024 Grapin Tech Solution</p>
            </div>
            <div className="policies">
              <a href="#privacy">Privacy & Policy</a>
              <a href="#cookie">Cookie Policy</a>
              <a href="#security">Security Policy</a>
            </div>
            <div className="policies2">
              <a href="#terms">Terms & Conditions</a>
              <a href="#customize">Customize Cookie</a>
            </div>
          </div>
          <div className="social-icons">
            <p>Follow Us On</p>
            <div className="icons">
              <img className="social-images" src={insta} />
              <img className="social-images" src={facebook} />
              <img className="social-images" src={whatsapp} />
              <img className="social-images" src={linkdin} />
            </div>
          </div>
        </div>
      </footer>
    
  );
};

export default Footer;
