import React, { useEffect, useRef } from "react";
import "./Submit.css";

const Submit = () => {
  const svgWrapperRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            svgWrapperRef.current.classList.add("animate-svg");
          }
        });
      },
      {
        threshold: 0.5, // Trigger when 50% of the section is visible
      }
    );

    const section = document.querySelector(".touch-container");
    if (section) {
      observer.observe(section);
    }

    return () => {
      if (section) observer.unobserve(section);
    };
  }, []);

  return (
    <div className="svg-containers">

        <div className="touch-container">
          {/* SVG Wrapper */}
          <div className="svg-wrapper" ref={svgWrapperRef}>
            <svg
              width="650"
              height="650"
              viewBox="0 0 860 623"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_f_928_1030)">
                <path
                  d="M709.999 294.169C709.999 403.437 620.265 518.753 513 518.753C408.5 544.459 185.965 596.661 131.499 474.237C73.0003 342.753 101.103 151.253 241 103.753C386.5 29.2529 672.936 68.4673 709.999 294.169Z"
                  fill="url(#paint0_linear_928_1030)"
                  fillOpacity="0.6"
                />
              </g>
              <defs>
                <filter
                  id="filter0_f_928_1030"
                  x="-47.2324"
                  y="-80.6299"
                  width="907.232"
                  height="782.711"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="75"
                    result="effect1_foregroundBlur_928_1030"
                  />
                </filter>
                <linearGradient
                  id="paint0_linear_928_1030"
                  x1="662.5"
                  y1="180"
                  x2="103"
                  y2="417"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#0038C6" />
                  <stop offset="1" stopColor="#C408B1" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          {/* Text and Form */}
          <div className="touch-row">
            <div className="touch-colum1">
              <h2 className="touch-heading">Get In Touch...</h2>
              <p className="touch-para">
                We’d love to hear from you!
                <br /> Whether you have questions,
                <br /> feedback, or business <br />
                inquiries, feel free to reach out.
              </p>
            </div>
            <div className="touch-colum2">
              <form>
                <div className="input-box">
                  <input type="text" required />
                  <label>Name</label>
                </div>
                <div className="input-box">
                  <input type="email" required />
                  <label>Email</label>
                </div>
                <div className="input-box">
                  <input type="text" required />
                  <label>Message</label>
                </div>
                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
        </div>
    </div>
  );
};

export default Submit;
