import Approach from "./Components/Approach";
import Footer from "./Components/Footer";
import Home from "./Components/Home";
import Nav from "./Components/Navbar/Nav";
import Submit from "./Components/Submit";


function App() {
  return (
    <div className="App">
      <Nav />
      <Home />
      <Approach />
      <Submit/>
      <Footer />
      
      
    </div>
  );
}

export default App;
